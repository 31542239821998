/* Google Font  */

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500&family=Outfit:wght@400;500&family=Plus+Jakarta+Sans:wght@400;500&family=Poppins&display=swap");
@import "~react-image-gallery/styles/css/image-gallery.css";
/* Font Family  */

/* font-family: 'Inter', sans-serif;
 font-family: 'Outfit', sans-serif;
 font-family: 'Plus Jakarta Sans', sans-serif;
 font-family: 'Poppins', sans-serif; */
.App {
  height: 100%;

  background-color: black;
  display: flex;
  flex-direction: column;
}

main {
  flex-grow: 1;
  /* Enables vertical scrolling */
  overflow-y: scroll;
  height: 80vh;
  /* The height can be set to 100vh for full viewport height */
  /* This property is essential for enabling scroll snapping */
  scroll-snap-type: y mandatory;

  &::-webkit-scrollbar {
    display: none;
  }
}

section {
  /* Sets the height of each section to full viewport height */

  /* Forces the section to adhere to the snap points */
  scroll-snap-align: start;
}

/* Default Css */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  background-color: #000;
  overflow-x: hidden;
}

ul,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

img {
  width: 100%;
}

.container {
  max-width: 1500px;
  width: 100%;
  margin: auto;
  padding: 0px 3%;
}

.heading1 {
  font-family: Plus Jakarta Sans;
  font-size: 52px;
  font-style: normal;
  font-weight: 500;
  line-height: 62px;
}

.heading2 {
  color: #fff;
  font-family: Plus Jakarta Sans;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 50px;
}

.heading3 {
  color: #fff;
  font-family: Plus Jakarta Sans;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 37px;
}

.heading4 {
  color: #000;
  font-family: Plus Jakarta Sans;
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: 35px;
}

.text_18 {
  color: #fff;
  font-family: Plus Jakarta Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.text_20 {
  color: #fff;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}
.image-gallery-icon{
  color: #757575a1;
}
.image-gallery-icon:hover {
  color: #ffffffa1;
}
.image-gallery-fullscreen-button{
  margin-right: 2em;
}
.btn {
  display: inline-flex;
  align-items: center;
  
  color: #fff;
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 37px;
  border-radius: 26.5px;
  border: 0px;
  background: #000;
  padding: .5em 2em;
  width: 10em;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  white-space: nowrap;
}

.cart_btn{
  background-color: #A0C6FF;
  border: 10px #ffffff;
  color: black;
  justify-content: center;
  font-weight: 500;
}
.cart_btn:hover{
  background-color: #0b2957
}
.details_btn {
  background-color: #d9d9d900;
  border-width: 2px;
  border-color: white;
  border-style: solid;
  color: rgb(255, 255, 255);
  justify-content: center;
  font-weight: 500;
}
.details_btn:hover {
  border-color: #4b4b4b
}
.btn img {
  width: 27px;
}
.card_container{
  margin: auto;
  margin-top: 1em;
  background-color: #21212100;
  border-style: none;
  border-radius: 5em;
  height: 25em;
  
  display: flex;
  flex-direction: row;
}
.card_footer{
  display: flex;
  align-items: center;
  
  gap: 1em;
}
.card_img{
  margin-left: 2.5em;
  max-width: 42em;
  align-self: center;
  align-items: center;
}
.header_cart_btn{
  margin-left: 2em;
}
.container_new{
  margin-bottom: -1em;
  color: #A0C6FF;
}
.card_img img{
  border-radius: 5em;
    width: 100%;
      -o-object-fit: contain;
      object-fit: contain;
      display: block;
}
.information_container{
  display: flex;
  flex-direction: column;
  gap: 1em;
  margin-left: 1em;
  margin-top: 2em;

}
/* ===================================================================
                            Header Area CSS Start
====================================================================*/
.header_area {
  top: 0;
  background-color: black;
  position: -webkit-sticky;
    position: sticky;
  z-index: 111;
}
/* Position and sizing of burger button */
.bm-burger-button {
  display: none;

}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #ffffff;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #b1b1b1;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #000000;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;

}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #bdc3c7;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}
.header_main {
  
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 35px 0;
}

.logo {
  max-width: 214px;
  flex: 0 0 30%;
  display: flex;
  flex-direction: row;
  align-content: center;
}

.logo img {
  width: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  display: block;
}

.header_menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  
}

.main_menu ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  gap: 49px;
}



/* ===================================================================
                            Banner Area CSS Start
====================================================================*/
.banner_area {
  padding: 262px 0 70px 0;
  position: relative;
  z-index: 1;
}

.banner_area:after {
  position: absolute;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 474px;
  height: 632px;
  top: -130px;
  left: 0;
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  z-index: -1;
}

.banner_content {
  text-align: center;
}

.banner_content h1 {
  text-align: center;
  max-width: 575px;
  margin: 0 auto;
  padding-bottom: 10px;
  background: -webkit-linear-gradient(left,
      #fff -10.42%,
      rgba(255, 255, 255, 0.3) 48.97%,
      #fff 113.01%);
  background: linear-gradient(90deg,
      #fff -10.42%,
      rgba(255, 255, 255, 0.3) 48.97%,
      #fff 113.01%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.banner_content .banner_text {
  text-align: center;
  max-width: 471px;
  margin: 0 auto;
  padding-bottom: 23px;
}

.banner_apps {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 16px;
}

.banner_apps img {
  max-width: 162px;
  height: 48px;
  width: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  display: block;
}

/* ===================================================================
                            Turning Area CSS Start
====================================================================*/

.turning_area {
  padding: 100px 0;
}

.turning_top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 54px;
  padding-bottom: 18px;
}

.turning_top_left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 15px;
}

.turning_top_sngle_img {
  border-radius: 20px;
  background: #dff4b4;
  max-width: 328px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 25px 0 0 25px;
}

.turning_top_sngle_img h4 {
  max-width: 219px;
}

.turning_top_sngle_img img {
  max-width: 220px;
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  display: block;
}

.turning_top_right h5 {
  max-width: 382px;
  padding-bottom: 12px;
}

.turning_top_right p {
  max-width: 471px;
  padding-bottom: 28px;
}

.turning_ottom {
  border-radius: 20px;
  background-image: url("./assets/images/home-img.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.turning_ottom h3 {
  padding: 140px 0 140px 69px;
  max-width: 321px;
}

/* ===================================================================
                            Control Area CSS Start
====================================================================*/
.control_area {
  padding: 100px 0;
}

.all_control_items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 32px;
}

.control_single_item {
  text-align: center;
}

.control_single_item img {
  max-width: 44px;
  width: 100%;
  display: block;
  -o-object-fit: contain;
  object-fit: contain;
  margin: 0 auto;
  padding-bottom: 16px;
}

.control_single_item p {
  max-width: 384px;
  opacity: 0.7;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
  margin: 0 auto;
  padding-top: 16px;
}

/* ===================================================================
                            Download  Area CSS Start
====================================================================*/

.download_area {
  padding-top: 100px;
}

.download_wrapper {
  max-width: 1025px;
  margin: 0 auto;
}

.main_download_area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 116px;
}

.download_img {
  max-width: 350px;
}

.download_img img {
  width: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  display: block;
}

.download_content {
  padding-top: 116px;
}

.download_content h2 {
  max-width: 446px;
  padding-bottom: 17px;
}

.download_content p {
  max-width: 471px;
  padding-bottom: 87px;
}

.download_apps {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: start;
}

/* ===================================================================
                            Image Area CSS Start
====================================================================*/
.image_area {
  margin-top: -278px;
  position: relative;
  z-index: 11;
}

.main_img_area {
  max-height: 703px;
}

.main_img_area img {
  max-width: 1492px;
  width: 100%;
  display: block;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

/* ===================================================================
                            Mood Area CSS Start
====================================================================*/
.mood_area {
  padding: 100px 0;
}

.question_tittle,
.mood_tittle {
  text-align: center;
}

.question_tittle h2,
.mood_tittle h2 {
  max-width: 642px;
  margin: 0 auto;
  padding-bottom: 17px;
}

.question_tittle p,
.mood_tittle p {
  opacity: 0.7;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
  max-width: 725px;
  margin: 0 auto;
  padding-bottom: 50px;
}

.mood_all_items {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -ms-grid-row-align: center;
  align-items: center;
}

.mood_all_items img {
  display: block;
  width: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.mood_left_bottom,
.mood_left_topright_top,
.mood_left_top,
.mood_all_items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 15px;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.mood_right,
.mood_left_top_right,
.mood_left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 15px;
}

.mood_left_bottom img:nth-child(2) {
  max-width: 227px;
}

/* ===================================================================
                            questions Area CSS Start
====================================================================*/
.questions_area {
  padding: 30px 0 30px 0;
}

.accordion_area {
  max-width: 799px;
  margin: 0 auto;
}

.accordion-item {
  margin-bottom: 15px;
}

.accordion-header {
  cursor: pointer;
  display: block;
  position: relative;
  padding: 37px 32px;
  border-radius: 14px;
  background: #212121;
  color: #fff;
  font-family: Plus Jakarta Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.36px;
}

.accordion-header>span {
  position: absolute;
  border: 2px solid #fff;
  border-radius: 50%;
  padding: 12px;
  right: 20px;
  top: 50%;
  height: 25px;
  width: 25px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: pointer;
}

.accordion-header>span:before {
  position: absolute;
  content: "";
  left: 50%;
  top: 50%;
  height: 14px;
  width: 2px;
  background-color: #fff;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: -webkit-transform cubic-bezier(0.165, 0.84, 0.44, 1) 0.3s;
  transition: -webkit-transform cubic-bezier(0.165, 0.84, 0.44, 1) 0.3s;
  transition: transform cubic-bezier(0.165, 0.84, 0.44, 1) 0.3s;
  transition: transform cubic-bezier(0.165, 0.84, 0.44, 1) 0.3s,
    -webkit-transform cubic-bezier(0.165, 0.84, 0.44, 1) 0.3s;
}

.accordion-header>span:after {
  position: absolute;
  content: "";
  left: 50%;
  top: 50%;
  height: 14px;
  width: 2px;
  background-color: #fff;
  -webkit-transform: translate(-50%, -50%) rotate(90deg);
  transform: translate(-50%, -50%) rotate(90deg);
}

.accordion-open .accordion-header>span:before {
  -webkit-transform: translate(-50%, -50%) rotate(90deg);
  transform: translate(-50%, -50%) rotate(90deg);
}

.accordion-body {
  padding: 20px;
  background-color: #000000;
  font-size: 18px;
  text-align: justify;
  display: none;
}

/* ===================================================================
                            Footer Area CSS Start
====================================================================*/
.footer_area {
  background: #212121;
}

.footer_copy_right_content p {
  text-align: center;
  padding: 35px 0;
}

@media all and (max-width: 1199px) {
  .banner_area:after {
    -webkit-transform: translateX(85%);
    transform: translateX(85%);
  }

  .mood_left_bottom img:nth-child(2) {
    max-width: 213px;
  }
}

@media all and (max-width: 1024px) {
  .banner_area:after {
    -webkit-transform: translateX(72%);
    transform: translateX(72%);
  }

  .mood_area,
  .control_area,
  .turning_area {
    padding: 80px 0;
  }

  .download_area {
    padding-top: 40px;
  }

  .download_content {
    padding-top: 80px;
  }

  .main_download_area {
    gap: 80px;
  }

  .mood_left_bottom img:nth-child(2) {
    max-width: 204px;
  }

  .accordion-header {
    padding: 28px 32px;
  }
}

@media all and (min-width: 991px) {
  
  .header_menu {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
}

@media all and (max-width: 991px) {
  .logo img {
      align-self: center;
      justify-self: center;
      margin-left: 3em;
    }
  .bm-burger-button {
      position: fixed;
      width: 36px;
      top: 2.5em;
      height: 25px;
      display: block;
  
    }
  .heading2 {
    font-size: 34px;
    line-height: 1.4;
  }
    .user_bar {

      scale: .7;
    }
  .card_container{
    padding-top: 1em;
    flex-direction: column;
    padding-bottom: 45em;
  }
  .card_img{
    margin: 0em 1em;
  }

  .mobile__bar {
    display: block;
  }

  .header_menu {
    display: none;
    gap: 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    position: absolute;
    top: 113px;
    left: 0;
    right: 0;
    background: #000;
    padding: 20px 0;
    padding-bottom: 40px;
    z-index: 11;
  }

  .main_menu ul {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -ms-grid-row-align: center;
    align-items: center;
    gap: 20px;
  }

  .header_btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding-top: 20px;
  }

  .banner_area:after {
    display: none;
  }

  .banner_content h1 {
    background: -webkit-linear-gradient(left,
        #fff -10.42%,
        rgb(255, 255, 255) 48.97%,
        #fff 113.01%);
    background: linear-gradient(90deg,
        #fff -10.42%,
        rgb(255, 255, 255) 48.97%,
        #fff 113.01%);
    background-clip: text;
  }

  .banner_area {
    padding: 50px 0;
  }

  .heading1 {
    font-size: 48px;
    line-height: 1.3;
  }

  .mood_area,
  .control_area,
  .turning_area {
    padding: 60px 0;
  }

  .turning_top {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .turning_top_right {
    text-align: center;
  }

  .turning_top_right h5 {
    max-width: 382px;
    margin: 0 auto;
  }

  .heading4 {
    font-size: 22px;
    line-height: 1.4;
  }

  .heading3 {
    font-size: 24px;
    line-height: 1.5;
  }

  .main_download_area {
    gap: 30px;
  }

  .download_content {
    padding-top: 20px;
  }

  .download_content p {
    padding-bottom: 30px;
  }

  .mood_left_bottom img:nth-child(2) {
    max-width: 205px;
  }

  .accordion-header {
    padding: 25px 32px;
  }
}

@media all and (max-width: 767px) {
  .heading1 {
    font-size: 40px;
  }

  .heading2 {
    font-size: 28px;
    line-height: 1.4;
  }

  .heading3 {
    font-size: 22px;
    line-height: 1.5;
  }

  .heading4 {
    font-size: 20px;
    line-height: 1.5;
  }

  .mood_area,
  .control_area,
  .turning_area {
    padding: 40px 0;
  }

  .turning_top {
    gap: 30px;
  }

  .turning_top_sngle_img {
    max-width: 225px;
  }

  .turning_ottom h3 {
    padding: 60px;
    max-width: 100%;
    text-align: center;
    margin: 0 auto;
  }

  .all_control_items {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .main_download_area {
    gap: 40px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -ms-grid-row-align: center;
    align-items: center;
  }

  .image_area {
    margin-top: 0;
  }

  .download_img {
    max-width: 300px;
  }

  .download_content {
    padding-top: 10px;
    text-align: center;
  }

  .download_apps {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding-bottom: 30px;
  }

  .mood_left_bottom img:nth-child(2) {
    max-width: 157px;
  }

  .mood_left_bottom,
  .mood_left_topright_top,
  .mood_left_top,
  .mood_all_items {
    gap: 10px;
  }

  .mood_right,
  .mood_left_top_right,
  .mood_left {
    gap: 10px;
  }

  .accordion-header {
    padding: 20px 32px;
  }
}

@media all and (max-width: 575px) {
  .heading1 {
    font-size: 36px;
  }
  
.card_container {
  flex-direction: column;
  padding-bottom: 40em;

  
}

.card_img  {

  margin: 0;
  margin-top: 1.5em;

}
.card_img img{
  margin-top: 1em ;
  border-radius: 0em;
  
}
  .heading2 {
    font-size: 25px;
    line-height: 1.4;
  }

  .heading3 {
    font-size: 18px;
    line-height: 1.5;
  }

  .heading4 {
    font-size: 18px;
  }

  .mood_area,
  .control_area,
  .turning_area {
    padding: 30px 0;
  }

  .turning_top_left {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .control_single_item p {
    padding-top: 5px;
  }

  .control_single_item img {
    padding-bottom: 5px;
  }

  .mood_all_items {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -ms-grid-row-align: center;
    align-items: center;
    max-width: 400px;
    justify-content: center;
    margin: 0 auto;
  }

  .download_img {
    max-width: 213px;
  }

  .accordion-header {
    padding: 18px 32px;
    font-size: 16px;
  }

  .mood_left_bottom,
  .mood_left_topright_top,
  .mood_left_top {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -ms-grid-row-align: center;
    align-items: center;
    max-width: 100%;
    justify-content: center;
  }

  .mood_left_top_left {
    width: 100%;
  }

  .mood_left_bottom img:nth-child(2) {
    max-width: 100%;
  }
}

@media all and (max-width: 450px) {
  .heading1 {
    font-size: 30px;
  }

  .heading2 {
    font-size: 22px;
    line-height: 1.4;
  }

  .text_20 {
    font-size: 18px;
  }

  .footer_copy_right_content p {
    padding: 20px 0;
    font-size: 14px;
  }

  .download_img {
    max-width: 177px;
  }
}



